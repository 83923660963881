<template>
    <div v-loading="loading">
      <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        :before-close="handleCloseDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <el-form
          :model="formData"
          :rules="formRules"
          ref="refForm"
          label-position="top"
          :status-icon="true"
        >
          <el-row :gutter="20">
            <!-- title  -->
            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
              
              <p style="font-weight: bold;" >Title</p>
            </el-col>
            
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <p style="margin-left: 12px;margin-top: 10px;" >{{ formData.title }}</p>
            </el-col>
          </el-row>
     
        
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <p style="font-weight: bold;margin-top: 25px;" >Message</p>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <p style="margin-left: 12px;margin-top: 10px;">{{ formData.message }}</p>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleCloseDialog()">Cancel</el-button>
          <!-- <el-button type="info" @click="sendChildFormData()">Save</el-button> -->
        </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
  //import { requiredRule } from "@/rules/all-rules";
  import { fetchClientList, fetchBrokersList } from "@/api/market-watch";
  import { fetchAllAdminList, adminBrokers, adminClients } from "@/api/super-admin/admin";
  import { mapGetters } from "vuex";
  //import getRoleSlug from "@/store/app.js";
  
  
  
  export default {
    name: "AddEditDialog",
    components: {},
    props: {
      dialogVisible: {
        type: Boolean,
        default: false,
      },
      dialogType: {
        type: String,
        default: "",
      },
      formData: {
        type: Object,
        default: function () {
          return {
            send_to: [],
          };
        },
      },
      formErrors: {
        type: Array,
        default: function () {
          return [];
        },
      },
    },
    data() {
      return {
        loading: false,
        formRules: {},
        formLabelWidth: "120px",
        listDataDropdownClients: {},
        listAdmins: {},
        radio: ''
      };
    },
    computed: {
       ...mapGetters(["getRoleSlug"]),
      dialogTitle() {
        return this.dialogType === "create"
          ? "Add Notification"
          : "Edit Notification";
      },
    },
    created() {
      // this.formRules.title = requiredRule("title");
      // this.formRules.client_selection = requiredRule("client_selection");
      // this.formRules.message = requiredRule("message");
      // if(getRoleSlug.state.roleSlug == 'admin' || getRoleSlug.state.roleSlug == 'broker') {
      //   this.getClientList();
      //   this.getBrokerList();
      // } 
      // if(getRoleSlug.state.roleSlug == 'superadmin') {
      //   this.getAdminList();
      // } 
    },
    methods: {
      
      handleSelect(value) {
        console.log(value)
        let send_to = [];
        this.$set(this.formData,'send_to', send_to)
        if (value == "all") {
          this.listDataDropdownClients.forEach((item) => {
            send_to.push(item.id);
          });
        } 
        this.$set(this.formData,'send_to', send_to)
      },
      handleSelectBroker(value) {
        console.log(value)
        let send_to = [];
        this.$set(this.formData,'send_to', send_to)
        if (value == "all") {
          this.listDataDropdownBrokers.forEach((item) => {
            send_to.push(item.id);
          });
        } 
        this.$set(this.formData,'send_to', send_to)
      },
      handleSelectClient(value) {
        console.log(value)
        let send_to = [];
        this.$set(this.formData,'send_to', send_to)
        if (value == "all") {
          this.listAdmins.forEach((item) => {
            send_to.push(item.id);
          });
        } 
        this.$set(this.formData,'send_to', send_to)
      },
      refresh() {
        console.log('refrehs')
        this.formData = {}
      },
      handleCloseDialog() {
        this.$emit("childClose");
      },
      sendChildFormData() {
        this.loading = true;
        //   console.log(this.formData)
        this.$refs.refForm.validate((valid) => {
          if (valid) {
            // WHEN EDIT MERGE NEW PARAMETERS
            if (this.dialogType === "edit") {
              this.formData = Object.assign(this.formData, {
                dialog_type: this.dialogType,
              });
            }
            this.$emit("getChildFormData", this.formData);
            this.loading = false;
          }
        });
      },
      getAdminList() {
        fetchAllAdminList().then((response) => {
          this.listAdmins  = response.data.data;
        });
      },
      getClientList() {
        this.loading = true;
        fetchClientList().then((response) => {
          this.listDataDropdownClients = response.data.data;
        });
      },
      getBrokerList() {
        this.loading = true;
        fetchBrokersList().then((response) => {
          this.listDataDropdownBrokers = response.data.data;
        });
      },
      handleAdminBrokerAndClientList(id) {
        this.handleAdminBrokerList(id)
        this.handleAdminClientList(id)
      },
      handleAdminBrokerList(id) {
        adminBrokers(id)
          .then((response) => {
            if (response.data.success === true) {
              this.listDataDropdownBrokers = response.data.data;
            } else {
              this.flashError(response.data.message);
              this.$message.warning(response.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        this.dialogFormVisible = true;
      },
      handleAdminClientList(id) {
        adminClients(id).then((response) => {
            if (response.data.success === true) {
              this.listDataDropdownClients = response.data.data;
            } else {
              this.flashError(response.data.message);
              this.$message.warning(response.data.message);
            }
        })
        .catch((err) => {
          console.log(err)
        });
      }
    },
  };
  </script>
  