<template>
  <div v-loading="loading" class="table-area">
    <el-row class="table-top">
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading">Notifications</h4>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        &nbsp;
        <!-- <div class="search-wrapper">
          <el-input
            placeholder="Search"
            v-model="search"
            class="input-with-select"
            clearable
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handleSearch()"
            ></el-button>
          </el-input>
        </div> -->
      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="btn-wrapper">
          <el-button type="primary" @click="handleCreate()">Send</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row style="padding-top: 50px" v-if="getRoleSlug == 'superadmin'">
          <Table
              tableKey="traderFunds"
              :tableConfig="config"
              :tableData="listData"
              :tableQuery="listQuery"
              :showSummary="false"
              :tableActionShowVisibility="false"
              :tableActionVisibility="false"
              :tableActionEditVisibility="false"
              :tableLoadingStatus.sync="loading"
            />
    </el-row>
       <el-tabs class="marketwatch_tab" type="card" v-if="getRoleSlug != 'superadmin'">
        <!-- <el-tab-pane label="Send" style="padding-top: 50px" v-if="getRoleSlug == 'superadmin'">
        

          </el-tab-pane> -->

        <el-tab-pane label="Send" style="padding-top: 50px" >
            <Table
              tableKey="traderFunds"
              :tableConfig="config"
              :tableData="listData"
              :tableQuery="listQuery"
              :showSummary="false"
              :tableActionShowVisibility="false"
              :tableActionVisibility="false"
              :tableActionEditVisibility="false"
              :tableLoadingStatus.sync="loading"
            />
        </el-tab-pane>
        <el-tab-pane label="Received" style="padding-top: 50px">
          <Table
              tableKey="recivedNotification"
              :tableConfig="configRecivedNotification"
              :tableData="listDataRecivedNotification"
              :tableQuery="listQuery"
              :showSummary="false"
              :tableActionShowVisibility="true"
              :tableActionVisibility="false"
              :tableActionEditVisibility="false"
              :tableLoadingStatus.sync="loading"
              @view="handleViewDetails($event)"
            />
        </el-tab-pane>
       </el-tabs>
  
    <AddEditDialog
      :dialog-visible.sync="dialogVisible"
      :dialog-type="dialogType"
      :form-errors.sync="formErrors"
      @getChildFormData="handleChildFormData($event)"
      @childClose="handleChildClose()"
    />
    <ShowDialog
      :dialog-visible.sync="showDialogVisible"
      :dialog-type="dialogType"
      :form-data="showData"
      :form-errors.sync="formErrors"
      @getChildFormData="handleChildFormData($event)"
      @childClose="handleChildClose()"
    />
  </div>
</template>

<script>
import Table from "@/components/Table/Table.vue";
import AddEditDialog from "@/views/notifications/components/AddEditDialog.vue";
import ShowDialog from "@/views/notifications/components/ShowDialog.vue";
import { fetchList, store, fetchListRecived, read } from "@/api/notification";
import { _ } from "vue-underscore";
import { mapGetters } from "vuex";
import eventBus from "@/eventBus";




export default {
  name: "ListTraderFunds",
  components: {
    Table,
    AddEditDialog,
    ShowDialog
  },
  data() {
    return {
      showDialogVisible:false,
      dialogVisible: false,
      dialogType: "create",
      loading: false,
      search: "",
      listDataTemp: null,
      formData: {},
      showData:{},
      formErrors: [],
      config: [
        {
          label: "Title",
          prop: "title",
          width: "",
          className: "blueFont",
        },
        {
          label: "Message",
          prop: "message",
          width: "",
          className: "blueFont",
        },
        {
          label: "Delivered At",
          prop: "delivered_at",
          width: "",
          className: "blueFont",
        },
      ],
      configRecivedNotification: [
        {
          label: "From",
          prop: "send_by",
          width: "",
          className: "blueFont",
        },
        {
          label: "Message",
          prop: "message",
          width: "",
          className: "blueFont",
        },
        {
          label: "Delivered At",
          prop: "delivered_at",
          width: "",
          className: "blueFont",
        },
      ],
      // listData: {},
      listQuery: {
        page: 1,
        limit: 20,
        search: "",
        searchJoin: "or",
        orderBy: "created_at",
        sortedBy: "desc",
      },
      listData: {
        data: {
          item: [],
        },
      },
      listDataRecivedNotification: {
        data: {
          item: [],
        },
      },
    };
  },
  computed: {
     ...mapGetters(["getRoleSlug"]),
  },
  created() {
     // events
    eventBus.$on("real-time-message", (val) => {
      console.log("VAL",val);
      this.getListRecived();
      this.flashSuccess("One New Notification");
    });
    this.getList();
    this.getListRecived();
  },
  methods: {
    // //////////////////
    // EMIT METHODS
    // //////////////////
    handleChildFormData(data) {
      if (data.dialog_type === "edit") {
        this.handleUpdate(data);
      } else {
        this.handleStore(data);
      }
    },

    handleViewDetails(data) {
      // read api call
      read(data)
          .then((response) => {
            if (response.data.success === true) {
              this.flashSuccess(response.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });




      this.showDialogVisible = true; 
      this.dialogType = "view";
      this.showData = data
      console.log("data", data )
    },
    // //////////////////
    // HANDLE SEARCH
    // //////////////////
    handleSearch() {
      this.listData.data.item = this.listDataTemp;
      let s = this.search;
      s = s.toLowerCase();
      console.log(s);
      this.listData.data.item = _.filter(this.listData.data.item, function (d) {
        return d["message"] && d["message"].toLowerCase().includes(s);
      });
    },
    // //////////////////
    // GET LIST DATA
    // //////////////////
    getList() {
      fetchList(this.listQuery).then((response)=> {
        this.listDataTemp =  this.listData.data.item = response.data.data.data
      })
    },
    // //////////////////
    // GET LIST DATA
    // //////////////////
    getListRecived() {
      fetchListRecived(this.listQuery).then((response)=> {
        this.listDataRecivedNotification.data.item = response.data.data.data
      })
    },
    // //////////////////
    // CREATE
    // //////////////////
    handleCreate() {
      this.formData = {};
      this.formErrors = [];
      this.dialogVisible = true;
      this.dialogType = "create";
    },
    handleChildClose() {
      this.dialogVisible = false;
      this.showDialogVisible = false;
    },
    handleStore(data) {
      this.loading = true;
      console.log(data);
      this.dialogVisible = false;
        store(data)
          .then((response) => {
            if (response.data.success === true) {
              this.dialogVisible = false;
              this.flashSuccess(response.data.message);
              this.getList();
              this.loading = false;
            } else {
              this.formErrors = this.validationErrors(response.data.message);
            }
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
};
</script>
