import request from '@/utils/request'

/*
@keyword : manager-api-hosts
*/
export function fetchList(query) {
  return request({
    url: 'notifications',
    method: 'get',
    params: query
  })
}


export function store(data) {
  return request({
    url: '/notifications/store',
    method: 'post',
    data
  })
}
export function read(data) {
  return request({
    url: '/notifications/read',
    method: 'post',
    data
  })
}


export function fetchListRecived(query) {
  return request({
    url: 'notifications/recieve',
    method: 'get',
    params: query
  })
}

